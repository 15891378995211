import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
// import './App.css'
import "./tailwind.css"
import Header from './components/Header'
import ContainerPlanner from './components/ContainerPlanner'

function App () {
  const containerSize = { L: 1, W: 1, H: 1 }
  const boxes = [
    { x: 0.1, y: 0.1, z: 0.1, l: 0.2, w: 0.2, h: 0.2, customerId: 1 },
    { x: 0.4, y: 0.4, z: 0.1, l: 0.2, w: 0.2, h: 0.2, customerId: 2 },
  ]
  return (
    <div className="App" >
      <Router>
        <Header />
        <div>
          <ContainerPlanner {...containerSize} boxes={boxes} />
        </div>
      </Router>
    </div>
  )
}

export default App
