import React, { useRef, useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import BoxObject from './BoxObject'
import { BoxGeometry } from 'three'

const getColor = (customerOrBox) => {
  const customerColors = [
    '#98FB98', // palegreen
    '#32CD32', // limegreen
    '#228B22', // forestgreen
    '#9ACD32', // yellowgreen
    '#6B8E23', // olivedrab
    '#66CDAA', // mediumaquamarine
    '#8FBC8B', // darkseagreen
    '#20B2AA', // lightseagreen
    '#00FF00', // lime
    '#008080', // teal
  ] // 不同颜色的绿色
  const boxColors = [
    '#CD5C5C',
    '#F08080',
    '#B8860B',
    '#FF0000',
    '#FFC0CB',
    '#FF69B4',
    '#C71585',
    '#FFA500',
    '#FF6347',
    '#FFD700',
    '#FFFF00',
    '#F0E68C',
    '#E6E6FA',
    '#DDA0DD',
    '#FF00FF',
    '#8A2BE2',
    '#8B008B',
    '#00FFFF',
    '#00BFFF',
    '#0000FF',
  ]
  if (customerOrBox === 'customer') {
    return customerColors
  } else {
    return boxColors
  }
}

const Scene = ({ data, curstep, curcustomer, Clength }) => {
  return (
    <>
      <lineSegments>
        <edgesGeometry args={[new BoxGeometry(Clength, 220, 233)]} />
        <lineBasicMaterial color="black" />
      </lineSegments>

      {data.slice(0, curstep).map((placePosition, i) => {
        return placePosition.map((box, j) => {
          const x = box.x
          const y = box.y
          const z = box.z
          const length = box.length
          const width = box.width
          const height = box.height
          const typeid = box.boxtype.type

          let color = null
          if (box.customerid === curcustomer) {
            color = getColor('box')[typeid - 1]
          } else {
            color = getColor('customer')[box.customerid - 1]
          }

          return (
            <BoxObject
              key={`box-${i}-${j}`}
              x={x}
              y={y}
              z={z}
              l={length}
              w={width}
              h={height}
              containerL={Clength}
              color={color}
            />
          )
        })
      })}
    </>
  )
}

const AllStepPrint = ({ placePositions, Clength }) => {
  const download = (step) => {
    const canvas = document.getElementById(step)
    console.log('canvas', canvas)
    requestAnimationFrame(() => {
      canvas.toBlob((blob) => {
        const a = document.createElement('a')
        const url = URL.createObjectURL(blob)
        a.href = url
        a.download = `step${step}.png`
        a.click()
        URL.revokeObjectURL(url)
      })
    })
  }

  const stepList = Array.from(
    { length: placePositions.length },
    (_, index) => index + 1
  )

  return (
    <div className="">
      {stepList.map((step, i) => {
        return (
          <Canvas
            key={i}
            id={step}
            className="border border-black"
            antialias={false}
            camera={{ fov: 100, near: 1, far: 5000, position: [500, 350, 0] }}>
            <ambientLight intensity={3.0} />

            <Scene
              data={placePositions}
              curstep={step}
              Clength={Clength}
              curcustomer={placePositions[step - 1][0].customerid}
            />

            <OrbitControls
              enableZoom={true}
              maxPolarAngle={Math.PI / 2}
              minPolarAngle={0}
              minDistance={160}
              maxDistance={500}
            />
          </Canvas>
        )
      })}
    </div>
  )
}

export default AllStepPrint
