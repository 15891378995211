import { Canvas } from '@react-three/fiber'
import React from 'react'
import { BoxGeometry } from 'three'
import { OrbitControls } from '@react-three/drei'
import BoxObject from './BoxObject'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AllStepPrint from './AllStepPrint'
import {
  faLeftLong,
  faRightLong,
  faUpload,
  faCameraRetro,
} from '@fortawesome/free-solid-svg-icons'

class ContainerPlanner extends React.Component {
  constructor(props) {
    super(props)
    this.fileInputRef = React.createRef()
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handlePrevStep = this.handlePrevStep.bind(this)
    this.handleNextStep = this.handleNextStep.bind(this)
    this.handleCaseChange = this.handleCaseChange.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handlePrint = this.handlePrint.bind(this)

    this.state = {
      selectValue: '', // 下拉列表选择要展示的客户信息
      selectjsonfile: '', // 选择要展示的json文件
      jsonData: null, // json 数据
      customerInfo: null, // 每个客户的信息
      packedBoxinfo: null, // 每个客户装箱后的信息
      step: 0, // 当前步骤
      stepNum: 0, // 总步骤数
      curcustomer: 0, // 当前步骤所在装的是哪个客户的货
      type: 'visible',
      isPrint: false,
    }
  }

  getColor = (customerOrBox) => {
    const customerColors = [
      '#98FB98', // palegreen
      '#32CD32', // limegreen
      '#228B22', // forestgreen
      '#9ACD32', // yellowgreen
      '#6B8E23', // olivedrab
      '#66CDAA', // mediumaquamarine
      '#8FBC8B', // darkseagreen
      '#20B2AA', // lightseagreen
      '#00FF00', // lime
      '#008080', // teal
    ] // 不同颜色的绿色
    const boxColors = [
      '#CD5C5C',
      '#F08080',
      '#B8860B',
      '#FF0000',
      '#FFC0CB',
      '#FF69B4',
      '#C71585',
      '#FFA500',
      '#FF6347',
      '#FFD700',
      '#FFFF00',
      '#F0E68C',
      '#E6E6FA',
      '#DDA0DD',
      '#FF00FF',
      '#8A2BE2',
      '#8B008B',
      '#00FFFF',
      '#00BFFF',
      '#0000FF',
    ]
    if (customerOrBox === 'customer') {
      return customerColors
    } else {
      return boxColors
    }
  }

  handleCaseChange = (event) => {
    const jsonPath = `data/${this.state.type}/` + event.target.value + '.json'
    this.setState({ selectjsonfile: event.target.value })

    fetch(jsonPath)
      .then((res) => res.json())
      .then((jsonData) => {
        this.setState({
          jsonData: jsonData,
          customerInfo: jsonData.customerInfos[0],
          packedBoxinfo: jsonData.packedCustomerInfos[0],
          selectValue: 1,
          step: 1,
          stepNum: jsonData.placePositions.length,
          curcustomer: jsonData.placePositions[0][0].customerid,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleFileChange = (event) => {
    const file = this.fileInputRef.current.files[0]
    if (!file) {
      alert('please select a file!')
      return
    }

    if (file.type !== 'application/json') {
      alert('please select a json file!')
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result)
        this.setState({
          jsonData: jsonData,
          customerInfo: jsonData.customerInfos[0],
          packedBoxinfo: jsonData.packedCustomerInfos[0],
          selectValue: 1,
          step: 1,
          stepNum: jsonData.placePositions.length,
          curcustomer: jsonData.placePositions[0][0].customerid,
        })
      } catch (error) {
        alert('Error parsing Json File!')
      } finally {
        this.fileInputRef.current.value = '' // Reset the input value
      }
    }
    reader.readAsText(file)
  }

  handleSelectChange = (event) => {
    const customerid = event.target.value
    this.setState({
      customerInfo: this.state.jsonData.customerInfos[customerid - 1],
      packedBoxinfo: this.state.jsonData.packedCustomerInfos[customerid - 1],
      selectValue: customerid,
    })
  }

  generateCustomerInfo = () => {
    const customerNum =
      this.state.jsonData == null ? 0 : this.state.jsonData.instance.customerNum
    const numList = Array.from({ length: customerNum }, (_, index) => index + 1)
    return (
      <div className="flex flex-wrap">
        {numList.map((num) => {
          return (
            <div className="inline text-sm py-1 px-3 leading-5 mr-3 flex flex-row" style={{
                  backgroundColor: this.getColor('customer')[num - 1],
                }}>
              <span className="inline-block h-5">{`Customer ${num}`}</span>
            </div>
          )
        })}
      </div>
    )
  }

  generateBoxInfo = () => {
    const boxNum =
      this.state.jsonData == null ? 0 : this.state.jsonData.instance.boxTypeNum
    const boxTypes =
      this.state.jsonData == null ? [] : this.state.jsonData.instance.boxTypes
    const numList = Array.from({ length: boxNum }, (_, index) => index + 1)
    return (
      <div className="flex flex-wrap">
        {numList.map((num) => {
          return (
            <div className="text-sm leading-5 text-white mr-1 border px-1 mb-1 border border-black w-24" style={{
                    backgroundColor: this.getColor('box')[num - 1],
                    textShadow: '1px 1px 2px gray',
                  }}>
              <div className="flex flex-row py-1">
                <span className="inline-block h-5">{`Type ${num}`}</span>
              </div>
              <div className="py-1">
                {boxTypes[num - 1].dimensions[0]}x
                {boxTypes[num - 1].dimensions[1]}x
                {boxTypes[num - 1].dimensions[2]}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  handlePrevStep = () => {
    if (this.state.step === 1) return
    this.setState({
      curcustomer:
        this.state.jsonData.placePositions[this.state.step - 2][0].customerid,
      step: this.state.step - 1,
    })
  }

  handleNextStep = () => {
    if (this.state.step === this.state.stepNum) return
    this.setState({
      curcustomer:
        this.state.jsonData.placePositions[this.state.step][0].customerid,
      step: this.state.step + 1,
    })
  }

  handleTypeChange = (event) => {
    this.setState({ type: event.target.value, selectjsonfile: 'Reset' })
  }

  // container packing画图
  drawPacking = () => {
    let canvas = null
    if (this.state.jsonData != null) {
      canvas = (
        <Canvas
          antialias={false}
          camera={{ fov: 50, near: 4, far: 2000, position: [0, 0, 900] }}>
          <ambientLight intensity={3.0} />

          <lineSegments>
            <edgesGeometry
              args={[new BoxGeometry(this.state.jsonData.length, 220, 233)]}
            />
            <lineBasicMaterial color="black" />
          </lineSegments>

          {this.state.jsonData.placePositions
            .slice(0, this.state.step)
            .map((placepositions, i) => {
              return placepositions.map((box, j) => {
                const x = box.x
                const y = box.y
                const z = box.z
                const length = box.length
                const width = box.width
                const height = box.height
                const typeid = box.boxtype.type

                let color = null
                if (box.customerid === this.state.curcustomer) {
                  color = this.getColor('box')[typeid - 1]
                  // if (this.state.step === this.state.stepNum) {
                  //   // 最后一个步骤，即使没有装完该客户的货，也要显示该客户的颜色
                  //   color = this.getColor('customer')[box.customerid - 1]
                  // } else {
                  //   color = this.getColor('box')[typeid - 1]
                  // }
                } else {
                  color = this.getColor('customer')[box.customerid - 1]
                }

                return (
                  <BoxObject
                    key={`box-${i}-${j}`}
                    x={x}
                    y={y}
                    z={z}
                    l={length}
                    w={width}
                    h={height}
                    containerL={this.state.jsonData.length}
                    color={color}
                  />
                )
              })
            })}

          <OrbitControls
            enableZoom={true}
            maxPolarAngle={Math.PI / 2}
            minPolarAngle={0}
            minDistance={160}
            maxDistance={2000}
          />
        </Canvas>
      )
    } else {
      canvas = (
        <Canvas
          antialias={false}
          camera={{
            fov: 40,
            near: 1,
            far: 2300,
            position: [0, 0, 900],
          }}></Canvas>
      )
    }
    return canvas
  }

  screenShot = () => {
    const canvas = document.getElementsByTagName('canvas')[0]
    requestAnimationFrame(() => {
      canvas.toBlob((blob) => {
        const a = document.createElement('a')
        const url = URL.createObjectURL(blob)
        a.href = url
        a.download = 'screenshot.png'
        a.click()
        URL.revokeObjectURL(url)
      })
    })
  }

  handlePrint = () => {
    this.setState({ isPrint: true })
  }

  printAll = () => {
    if (this.state.isPrint === false) return
    return (
      <AllStepPrint
        placePositions={this.state.jsonData.placePositions}
        Clength={this.state.jsonData.length}
      />
    )
  }

  render() {
    // data文件夹下的json文件名
    let jsonFiles = { visible: [], touchable: [], separated: [] }
    let req = require.context('../../public/data/visible/', false, /\.json$/)
    req.keys().forEach((key) => {
      jsonFiles.visible.push(key.split('/')[1].split('.')[0])
    })

    req = require.context('../../public/data/touchable/', false, /\.json$/)
    req.keys().forEach((key) => {
      jsonFiles.touchable.push(key.split('/')[1].split('.')[0])
    })

    req = require.context('../../public/data/separated/', false, /\.json$/)
    req.keys().forEach((key) => {
      jsonFiles.separated.push(key.split('/')[1].split('.')[0])
    })

    let jsonlist = null
    if (this.state.type === 'visible') {
      jsonlist = jsonFiles.visible
    } else if (this.state.type === 'touchable') {
      jsonlist = jsonFiles.touchable
    } else {
      jsonlist = jsonFiles.separated
    }

    // 下拉列表选择展示客户信息
    let selection = null
    const customerInfos =
      this.state.jsonData == null ? [] : this.state.jsonData.customerInfos
    selection = (
      <select
        className="text-center text-sm font-normal text-center py-2 px-3 border-0 shadow-md bg-gray-100 mb-2"
        value={this.state.selectValue}
        onChange={this.handleSelectChange}>
        <option value="">select a customer</option>
        {customerInfos.map((customerInfo, index) => {
          return (
            <option
              style={{
                backgroundColor:
                  this.getColor('customer')[customerInfo.customerId - 1],
              }}
              value={customerInfo.customerId}>
              {'Customer ' + customerInfo.customerId}
            </option>
          )
        })}
      </select>
    )

    // 显示box信息
    let boxesInfo = null
    if (this.state.customerInfo != null) {
      const boxes = this.state.customerInfo.boxTypes
      const packed = this.state.packedBoxinfo.boxTypes
      boxesInfo = (
        <div className="max-h-[360px] overflow-y-auto">
          <table className="overflow-clip border border-collapse text-center py-2 text-xs">
            <tr className="text-light">
              <td
                className="py-1 px-1 border border-black"
                style={{
                  backgroundColor:
                    this.getColor('customer')[
                      this.state.customerInfo.customerId - 1
                    ],
                }}>
                Box Type
              </td>
              <td className="py-1 px-1 border border-black">Dimension</td>
              <td className="py-1 px-1 border border-black">Packed</td>
              <td className="py-1 px-1 border border-black">Total</td>
            </tr>
            <tbody>
              {boxes.map((box, index) => {
                return (
                  <tr>
                    <td
                      className="py-1 px-1 border border-black"
                      style={{
                        backgroundColor:
                          this.getColor('customer')[
                            this.state.customerInfo.customerId - 1
                          ],
                      }}>
                      {box.type}
                    </td>
                    <td className="py-1 px-1 border border-black">
                      {box.dimensions[0]}x{box.dimensions[1]}x
                      {box.dimensions[2]}
                    </td>
                    <td className="py-1 px-1 border border-black">
                      {box.count - packed[index].count}
                    </td>
                    <td className="py-1 px-1 border border-black">
                      {box.count}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    }

    // 该 solution 装多少个 box
    let boxNum = 0
    if (this.state.jsonData != null) {
      for (let i = 0; i < this.state.jsonData.placePositions.length; i++) {
        boxNum += this.state.jsonData.placePositions[i].length
      }
    }

    return (
      <div className="">
        {/* three.js 画图 */}
        <div className="bg-gray-100 relative" style={{ height: '560px' }}>
          <div className="" style={{ height: '560px' }}>
            {this.drawPacking()}
          </div>

          {/* 按钮 */}
          <div className="h-7 md:h-10">
            {/* 装箱步骤切换按钮以及JSON提交 */}
            <div className="w-60 absolute bottom-2 left-2">
              <div className="inline-block w-6 h-6 md:w-10 md:h-10 bg-red-600 rounded-full mx-3">
                <button
                  className="w-6 h-6 md:w-10 md:h-10"
                  onClick={this.handlePrevStep}>
                  <FontAwesomeIcon icon={faLeftLong} style={{ color: 'fff' }} />
                </button>
              </div>
              <span className="text-sm md:text-md">
                {this.state.step} of {this.state.stepNum} step(s)
              </span>
              <div className="inline-block w-6 h-6 md:w-10 md:h-10 bg-red-600 rounded-full mx-3">
                <button
                  className="w-6 h-6 md:w-10 md:h-10"
                  onClick={this.handleNextStep}>
                  <FontAwesomeIcon
                    icon={faRightLong}
                    style={{ color: 'fff' }}
                  />
                </button>
              </div>
            </div>

            {/* 打印按钮, 选择json按钮 */}
            <div className="absolute bottom-2 right-2">
              <div className="inline-block mr-2 md:mr-8">
                <button
                  className="w-6 h-6 md:w-16 md:h-10 bg-red-600 rounded-full text-white hover:bg-red-600 focus:outline-none"
                  onClick={this.screenShot}>
                  <FontAwesomeIcon
                    icon={faCameraRetro}
                    style={{ color: '#f7f7f8' }}
                  />
                </button>
              </div>
              <div className="inline-block">
                <input
                  type="file"
                  ref={this.fileInputRef}
                  className="hidden"
                  accept=".json"
                  onChange={this.handleFileChange}
                />
                <button
                  className="w-6 h-6 md:w-16 md:h-10 bg-red-600 rounded-full text-white hover:bg-red-600 focus:outline-none"
                  onClick={() => this.fileInputRef.current.click()}>
                  <FontAwesomeIcon
                    icon={faUpload}
                    style={{ color: '#ffffff' }}
                  />
                </button>
              </div>
            </div>
          </div>

          {/* 选择展示的 instance */}
          <div className="absolute top-20 right-5">
            <select
              className="text-center text-sm font-normal text-center py-2 px-3 border-0 shadow-md bg-gray-100"
              value={this.state.selectjsonfile}
              onChange={this.handleCaseChange}>
              <option value="Reset">select a case</option>
              {jsonlist.map((file, index) => {
                return (
                  <option key={index} value={file}>
                    {file}
                  </option>
                )
              })}
            </select>
          </div>

          {/* 选择 multi-drop 的类型 */}
          <div className="absolute top-10 right-5">
            <label className="mr-3">
              <input
                type="radio"
                name="option"
                value="visible"
                checked={this.state.type === 'visible'}
                onChange={this.handleTypeChange}
              />{' '}
              visible
            </label>
            <label className="mr-3">
              <input
                type="radio"
                name="option"
                value="touchable"
                checked={this.state.type === 'touchable'}
                onChange={this.handleTypeChange}
              />{' '}
              touchable
            </label>
            <label className="mr-3">
              <input
                type="radio"
                name="option"
                value="separated"
                checked={this.state.type === 'separated'}
                onChange={this.handleTypeChange}
              />{' '}
              separated
            </label>
          </div>
        </div>

        {/* 信息展示 */}
        <div className="border md:flex">
          {/* container信息和box信息 */}
          <div className="text-left mx-5 md:border-r-2">
            {/* container信息 */}
            <div className="pt-3 px-3">
              <p className="font-bold">Container</p>
              <p className="text-sm">
                Size：
                {this.state.jsonData != null ? this.state.jsonData.length : 0}x
                {this.state.jsonData != null ? 233 : 0}x
                {this.state.jsonData != null ? 220 : 0}
              </p>
              <p className="text-sm">
                Utilization:{' '}
                {this.state.jsonData != null
                  ? this.state.jsonData.utilization.toFixed(3)
                  : 0.0}
              </p>
              <p className="text-sm">
                Packed Box Num: {this.state.jsonData != null ? boxNum : 0}
              </p>
            </div>

            {/* 显示box信息 */}
            <div className="pt-3 px-3">
              <p className="font-bold py-1">Boxes</p>
              {selection}
              {boxesInfo}
            </div>
          </div>

          {/* customer 和 box 的颜色说明 */}
          <div className="text-left">
            <div className="my-2 border-b-1">
              <div className="font-bold">Customer</div>
              {this.generateCustomerInfo()}
            </div>

            <div className="my-2">
              <div className="font-bold mb-2">Box Type</div>
              {this.generateBoxInfo()}
            </div>
          </div>
        </div>

        {/* {this.printAll()}

        <button
          className="border border-black object-center"
          onClick={this.handlePrint}>
          Print
        </button> */}
      </div>
    )
  }
}

export default ContainerPlanner
