import React from 'react';

class Header extends React.Component {

  render(){
    return (
      <header className="py-3 border-b text-red-800">
        <div className="container mx-auto relative">
          <div className="text-2xl mt-2 text-center">
          <span className="ml-3" >
            Container Loading With Multi Drop Constraint
          </span>
          </div>
        </div>
      </header>
    );
  }
  
}

export default Header;

