import React, {useEffect, useState} from 'react';
import {useFrame} from '@react-three/fiber'
import * as THREE from 'three';

function BoxObject(props) {
    const x = props.x + props.l/2 - props.containerL / 2;
    const y = props.z + props.h/2 -110;
    const z = 116.5 - props.y - props.w/2;
    const length = props.l;
    const width = props.w;
    const height = props.h;
    
    const boxMesh = React.useRef();
    const boxLines = React.useRef();

    const geometry = new THREE.BoxGeometry(length, height, width);
    const color = props.color;

    function showlineSegments(){
        return (
            <lineSegments ref={boxLines} position={[x, y, z]} >
                <edgesGeometry args={[geometry]} />
                <lineBasicMaterial color={0x000} linewidth={2}/>
            </lineSegments>
        );
    }

    return (
        <>
        <mesh position={[x, y, z]} ref={boxMesh}>
            <boxGeometry args={[length-1, height-1, width-1]} />
            <meshLambertMaterial color={color}/>
        </mesh>
        {showlineSegments()}
        </>
    )
}

export default BoxObject;